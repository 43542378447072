<template>
  <div>
    <v-btn v-if="ruoloPianificatore" x-large block color="primary" class="my-2" title="Crea nuova lavorazione" @click="goToCreaLavorazione">
      <v-icon x-large>
        mdi-plus
      </v-icon>
    </v-btn>   
      <v-card>
          <v-card-title class="text-h6 text-uppercase font-weight-regular py-2 panelheader">Lavorazioni</v-card-title>
          <v-card-text>
            <v-chip 
              v-for="vehicle in vehicles" 
              :key="vehicle.id" 
              @click="onClickSelectedVehicle(vehicle)" 
              :color="vehicleChipColor(vehicle)"
              class="pa-3 ma-3 elevation-1"
              large>
                <span :class="`text-h6 font-weight-regular ${vehicleChipTextColor(vehicle)}`">{{vehicle.codice}} - {{vehicle.descrizione}}</span>
            </v-chip>
            <v-simple-table dense class="py-1 ma-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-body-1 text-left text-uppercase font-weight-regular header">ID Lav.</th>
                    <th class="text-body-1 text-left text-uppercase font-weight-regular header">Stato</th>
                    <th class="text-body-1 text-left text-uppercase font-weight-regular header">Contatore</th>
                    <th class="text-body-1 text-left text-uppercase font-weight-regular header">Viaggi</th>
                    <th :colspan="ruoloPianificatore?'2':null" class="text-body-1 text-left text-uppercase font-weight-regular header">Operazioni</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(viaggi, idLavorazione) in datiLavs" :key="idLavorazione">
                    <td class="text-body-1 text-left font-weight-black">{{ idLavorazione }}</td>
                    <td class="text-body-1 text-left font-weight-regular">{{ viaggi[0].lavorazioneStato }}</td>
                    <td class="text-body-1 text-left font-weight-regular">{{ viaggi[0].cntLavorati }} / {{ viaggi[0].cntTotali }}</td>
                    <td>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">Codice</th>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">Nome</th>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">Provenienza</th>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">ETA</th>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">Parco</th>
                              <th class="text-body-2 text-left text-uppercase font-weight-regular">Binario</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="viaggio in viaggi" :key="viaggio.codice">
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.viaggioTrenoCodice}}</td>
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.viaggioTrenoNome}}</td>
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.viaggioTrenoProvenienzaLabel}}</td>
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.viaggioTrenoEta}}</td>
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.binarioParcoBinariCodice}}</td>
                              <td class="text-body-2 text-left font-weight-black">{{ viaggio.binarioNumero}}</td>                              
                            </tr>
                          </tbody>
                        </template>
                    </v-simple-table>
                    </td>
                    <td class="text-center">
                      <v-btn block color="primary" @click="$router.push({ path: `/lavorazioni/${idLavorazione}` })" title="Lavora" x-large 
                        :disabled="!ruoloPianificatore && viaggi[0].lavorazioneStato!=='PRONTA'">
                          <v-icon x-large>mdi-format-list-checks</v-icon>
                      </v-btn>
                    </td>
                    <td class="text-center" v-if="ruoloPianificatore">
                      <v-btn block color="primary" @click="doDelete(idLavorazione)" title="Elimina lavorazione" x-large>
                        <v-icon x-large>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
      </v-card>
  </div>
</template>

<script>
import ToastMixin from '../../mixins/ToastMixin';
export default {
  data() {
    return {
      viaggiArrivo: [],
      vehicles: [],
      selectedVehicle: undefined,
    };
  },
  mixins: [ToastMixin],
  async mounted() {
    await this.retrieveData();
  },
  computed: {
    ruoloPianificatore(){
      const roles=this.$store.getters['authentication/loggedUser'].roles;
      if (roles) {
        return roles.includes("ADMINISTRATOR") || roles.includes("USER");
      }
      return false;
    },
    datiLavs() {
      var datiLavs = {};
      if (this.viaggiArrivo) {
        this.viaggiArrivo.forEach( v => { 
          if (v.lavorazioneId !== null && this.selectedVehicleRails.includes(v.binarioId)) {
            if (datiLavs[v.lavorazioneId] == null) {
              datiLavs[v.lavorazioneId]=[];
            }
            datiLavs[v.lavorazioneId].push(v);
          }
        });     
      }
      console.log("datiLavs", datiLavs);
      return datiLavs;
    },
    selectedVehicleRails() {
      var rails = [];
      if (this.selectedVehicle && this.selectedVehicle.binariMezzo && this.selectedVehicle.binariMezzo.length > 0) {
        this.selectedVehicle.binariMezzo.forEach(bm => {
          rails.push(bm.binarioId);
        });
      }
      return rails;
    }
  },
  methods: {
    async retrieveData() {
      this.viaggiArrivo = await this.$api.trainData.getViaggiArrivoLavorazioneCrane();
      this.selectedVehicle = this.$store.getters['configuration/craneSelectedVehicle'](this.$router.history.current.path);
      this.vehicles = await this.$api.get(this.$apiConfiguration.BASE_PATH + "mezziMovimentazione/findAll");        
    },
    async doDelete(id) {
      try {
        const resp=await this.$api.trainData.eliminaLavorazione(id);
        if (resp) {
          this.showSuccess("Lavorazione eliminata");
          this.retrieveData();
          return;
        }
      } catch (e) {
        this.showError("Errore cancellazione lavorazione: " + e);
      }
    },
    goToCreaLavorazione() {
      this.$router.push({path:"/creaLavorazione"});
    },
    onChangeSelectedVehicle() {
      const data = {key:this.$router.history.current.path , craneSelectedVehicle:this.selectedVehicle};
      this.$store.commit('configuration/setCraneSelectedVehicle', data);
    },
    onClickSelectedVehicle(selectedVehicle) {
      this.selectedVehicle = selectedVehicle;
      const data = {key:this.$router.history.current.path , craneSelectedVehicle:this.selectedVehicle};
      this.$store.commit('configuration/setCraneSelectedVehicle', data);
    },
    vehicleChipColor(vehicle) {
      if (vehicle!=null && this.selectedVehicle!=null && vehicle.id === this.selectedVehicle.id) {
        return "primary";
      }
      return "grey lighten-3";     
    },
    vehicleChipTextColor(vehicle) {
      if (vehicle!=null && this.selectedVehicle!=null && vehicle.id === this.selectedVehicle.id) {
        return "white--text";
      }
      return "";     
    }
  },
};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./lavorazioni.vue?vue&type=template&id=f46b5734&scoped=true"
import script from "./lavorazioni.vue?vue&type=script&lang=js"
export * from "./lavorazioni.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46b5734",
  null
  
)

export default component.exports